'use client'

import { useTranslations } from 'next-intl'

import type { ContentTextProps } from '@fortum/elemental-ui'
import { ContentText } from '@fortum/elemental-ui'

import { Link } from '@/i18n/navigation'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'
import type { Theme } from '@/style/theme'

type PricePortalProps = ContentTextProps & {
  theme?: 'light' | 'dark'
}

/**
 * TODO
 * this method also met in Price component
 * so it might be a good idea to move it somewhere else
 */

const defineTheme = (colors: Theme['colors'], theme: PricePortalProps['theme'] = 'light') => {
  switch (theme) {
    case 'light':
      return {
        textColor: colors.textPrimary,
        linkColor: colors.textLink,
      }

    case 'dark':
      return {
        textColor: colors.textLightOnDark,
        linkColor: colors.textLightOnDark,
      }
  }
}

export const PricePortal: React.FC<PricePortalProps> = ({ theme = 'light', ...props }) => {
  const t = useTranslations('purchaseFlow')
  const { colors } = useTheme()

  if (!countryConfig.features.contractLegalText) {
    return null
  }

  const { textColor, linkColor } = defineTheme(colors, theme)
  const pricePortalMessage = t.rich('pricePortalText', {
    a: (chunks) => (
      <Link
        style={{
          color: linkColor,
        }}
        target="_blank"
        href={t('pricePortalLink')}
      >
        {chunks}
      </Link>
    ),
  })

  return (
    <ContentText color={textColor} {...props}>
      {pricePortalMessage}
    </ContentText>
  )
}
