'use client'

import { useTranslations } from 'next-intl'

import { Box, ContentText } from '@fortum/elemental-ui'

import { getDefaultStartDateByPriceType } from '@/open-web/services/calculators/utils/startDateUtils'
import { sumPricesByType } from '@/open-web/services/calculators/utils/utils'
import { logError } from '@/shared/utils/error'
import {
  SEASON_ENERGY_PRICE_ELEMENTS,
  TARIFF_ELEMENT_TYPE_PREFIX,
  isEnergyElement,
  isMonthlyFeeElement,
} from '@/shared/utils/tariffElementUtils'

import { EnergyPrice } from './EnergyPrice'
import { MonthlyFee } from './MonthlyFee'
import { useSelectedAddonsTariffNos } from '../../PriceBreakdown/hooks/useSelectedAddonsTariffNos'
import { calculateFinnishMonthlySavings } from '../../PriceBreakdown/utils'
import { PriceBreakdownInfoIcon } from '../../PriceBreakdownInfoIcon'
import type { PriceProps } from '../types'
import { validateCustomerType } from '../utils'
import { TotalPrice } from './TotalPrice'

export const FinnishPrice: React.FC<PriceProps> = ({
  contractTemplate,
  campaignConfiguration,
  contractProduct,
  mode = 'full',
  theme = 'light',
  priceBreakdownMode = 'default',
  priceSize = 'xl',
  isPriceBreakdownDisabled,
}) => {
  const { error: contractError } = validateCustomerType(contractTemplate)
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })
  const startDate = getDefaultStartDateByPriceType(contractTemplate.priceType)

  const isHybrid = contractTemplate.priceType === 'HYBRID'
  const isSpot = contractTemplate.priceType === 'SPOT'
  const isFlatRate = contractTemplate.priceType === 'FLAT_RATE'
  const hasPricingLabel = isHybrid || isSpot

  if (contractError) {
    throw logError(contractError)
  }

  const energyTariffElement = contractTemplate.tariffElements.find((element) =>
    isEnergyElement(element.type),
  )
  const monthlyFeeTariffElement = contractTemplate.tariffElements.find((element) =>
    isMonthlyFeeElement(element.type),
  )

  const totalMonthlyFees = sumPricesByType(
    contractTemplate,
    contractTemplate.availableAddons.filter((addon) =>
      selectedAddonsTariffNos.includes(addon.tariffNo),
    ),
    startDate,
    'FI',
    (type) => type === TARIFF_ELEMENT_TYPE_PREFIX.monthlyFee,
  )

  const totalEnergyPrice = sumPricesByType(
    contractTemplate,
    contractTemplate.availableAddons.filter((addon) =>
      selectedAddonsTariffNos.includes(addon.tariffNo),
    ),
    startDate,
    'FI',
    // energy elements also contains elements that we don't want to include in the total price
    // calculation, so we need to filter them out
    (type) => !SEASON_ENERGY_PRICE_ELEMENTS.includes(type) && isEnergyElement(type),
  )

  const { monthlyFeeSavings, energyFeeSavings } = calculateFinnishMonthlySavings(
    selectedAddonsTariffNos,
    contractTemplate,
  )

  const fullLayout = (
    <>
      <PriceBreakdownInfoIcon
        mode={priceBreakdownMode}
        theme={theme}
        contractTemplate={contractTemplate}
        campaignConfiguration={campaignConfiguration}
        contractProduct={contractProduct}
        isDisabled={isPriceBreakdownDisabled}
        addonsTariffNos={selectedAddonsTariffNos}
      >
        <ContentText size="s" color="inherit">
          {tPurchaseFlow('priceDetailsButtonText')}
        </ContentText>
      </PriceBreakdownInfoIcon>

      {energyTariffElement && !isFlatRate && (
        <EnergyPrice
          tariffElement={energyTariffElement}
          contractTemplate={contractTemplate}
          isHourlyPriced={isSpot}
          priceSize={priceSize}
          theme={theme}
        />
      )}

      {monthlyFeeTariffElement && (
        <MonthlyFee
          tariffElement={monthlyFeeTariffElement}
          renderLabel={!isFlatRate}
          contractTemplate={contractTemplate}
          priceSize={priceSize}
          theme={theme}
        />
      )}
    </>
  )

  const minimalLayout = (
    <>
      <Box
        display="flex"
        data-testid="sticky-footer-total-price"
        justifyContent="end"
        flexDirection={{ default: 'column', l: 'row' }}
      >
        {totalEnergyPrice && totalMonthlyFees && (
          <TotalPrice
            contractTemplate={contractTemplate}
            totalMonthlyFees={totalMonthlyFees}
            totalEnergyPrice={totalEnergyPrice}
            monthlyFeeDiscount={monthlyFeeSavings}
            energyFeeDiscount={energyFeeSavings}
            showPricingLabel={hasPricingLabel}
            priceSize={priceSize}
          />
        )}
      </Box>
    </>
  )

  return <Box>{mode === 'full' ? fullLayout : minimalLayout}</Box>
}
