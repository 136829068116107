import { useTranslations } from 'next-intl'

import type { FontSize } from '@fortum/elemental-ui'
import { ContentText, fontWeights, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type { TariffElement } from '@/shared/graphql/schema/commonBackend/graphql'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { defineTheme, getCurrentPrice } from '../utils'

export type EnergyPriceProps = {
  tariffElement: TariffElement
  contractTemplate: EnrichedContractTemplate
  isHourlyPriced?: boolean
  priceSize?: FontSize
  theme?: 'light' | 'dark'
  renderLabels?: boolean
}

export const EnergyPrice = ({
  tariffElement,
  isHourlyPriced = false,
  contractTemplate,
  theme = 'light',
  priceSize = 'xl',
  renderLabels = true,
}: EnergyPriceProps) => {
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const { colors } = useTheme()
  const { formatPriceWithUnit } = usePriceToString()
  const colorConfiguration = defineTheme(colors, theme)

  const energyPriceElement = getCurrentPrice(tariffElement.prices)

  const energyPrice = getPriceBaseOnCustomerType(energyPriceElement, contractTemplate.customerType)

  const formattedEnergyPrice = formatPriceWithUnit(energyPrice, tariffElement.priceUnit)

  return (
    <>
      {renderLabels && (
        <ContentText size="s" color={colorConfiguration.crossedPrice} mt={spacing.xxxs}>
          {tPurchaseFlow('electricityPrice')}
        </ContentText>
      )}
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        data-testid="contract-price"
      >
        {`${isHourlyPriced && renderLabels ? `${tPurchaseFlow('hourlyPrice')} + ` : ''}${formattedEnergyPrice}`}

        {renderLabels && contractTemplate.priceType === 'HYBRID' && (
          <ContentText size="s" display="block" tag="span" color={colorConfiguration.price}>
            +/- {tPurchaseFlow('consumptionImpact')}
          </ContentText>
        )}
      </ContentText>
    </>
  )
}
